


































































































































































































































































































































































































































































::v-deep .el-table {
    th {
        padding: 0px ;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
}
.item-width  {
    width: 48%;
    color:#409EFF;
}
.item-width2  {
    width: 97%;
    color:#409EFF;
}

.top{
    padding: 10px;
    background: rgba(0, 153, 229);
    color: #fff;
    text-align: center;
    font-size:10px;
    border-radius: 2px;
}  


